<template lang="">
    <Fieldset :legend="form.id ? 'Alteração - Prestador: ' + prestador.name : 'Inclusão - Prestador: ' + prestador.name ">
        <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
                <label for="cliente">Cliente</label>
                <DropdownCliente id="cliente" v-model="form.cliente" :disabled="!!form.id"></DropdownCliente>
            </div>

            <div class="field col-12 md:col-1">
                <Button icon="pi pi-check" class="p-button-text p-button-primary" v-tooltip="'Salvar'" style="margin-top: 24px" @click="save" :loading="loadingSave" />
                <Button
                    v-if="form.id"
                    icon="pi pi-times"
                    class="p-button-text p-button-secondary"
                    v-tooltip="'Cancelar'"
                    style="margin-top: 24px; margin-left: 5px"
                    @click="cancel"
                />
            </div>
        </div>
    </Fieldset>
</template>
<script>
import DropdownCliente from '../../clientes/components/DropdownCliente';
import BaseService from '../../../services/BaseService';

export default {
    components: { DropdownCliente },
    emits: ['onSave'],
    data() {
        return {
            form: {},
            loadingSave: false,
            prestador:{}
        };
    },
    created() {
        this.$service = new BaseService('/prestador-clientes');
    },
    async mounted() {
        this.$servicePrestador = new BaseService('/suppliers');
        await this.carregarPrestador();
    },
    methods: {
        async save() {
            try {
                this.loadingSave = true;
                const idPrestador = this.$route.params.id;
                const idCliente = this.form.cliente.id;
                const dto = {
                    id: this.form.id,
                    idPrestador,
                    idCliente
                };
                const saved = await this.$service.save(dto);
                this.form = {
                    valores: {}
                };
                this.$emit('onSave', saved);
                this.loadingSave = false;
            } catch (err) {
                this.loadingSave = false;
                const message = err?.response?.data?.message || err.message;
                this.$toast.add({ severity: 'error', summary: message, life: 5000 });
            }
        },
        async carregarPrestador() {
            const { data } = await this.$servicePrestador.findById(this.$route.params.id);
            this.prestador = data;
        },
        cancel() {
            this.form = {};
        },
        loadData(form) {
            this.form = JSON.parse(JSON.stringify(form));
        }
    }
};
</script>
