<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="text-800 mb-1">
                <span class="text-primary pi pi-info-circle mb-3" v-tooltip=tooltip></span> {{subtitle}}
            </div>
                <FormCliente @onSave="load" ref="form"></FormCliente>

                <Fieldset legend="Clientes" style="margin-top: 10px">
                    <Grid @onRemove="load" @onEdit="onEdit" ref="grid"></Grid>
                </Fieldset>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentCompany } from '@/services/store';
import Grid from './components/Grid.vue';
import FormCliente from './components/FormCliente.vue';

export default {
    data(){
        return{
            subtitle: 'Prestador/Cliente',
            tooltip: 'Liberação de cliente para o prestador'
        }
    },
    components: { Grid, FormCliente },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar prestador procedimento' : 'Adicionar prestador procedimento';
        },
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        onEdit(record) {
            this.$refs.form.loadData(record);
        }
    }
};
</script>

