<template lang="">
    <Dialog :style="{ width: '850px' }" header="Procedimentos para o cliente" :modal="true">
        
        <FormProcedimento ref="form" @onSave="load" :cliente="cliente"></FormProcedimento>
        
        <Fieldset legend="Clientes" style="margin-top: 10px">
            <GridExames ref="grid" @onRemove="load" @onEdit="onEdit" :cliente="cliente"></GridExames>
        </Fieldset>
        
        <template #footer>
            <Button 
                label="Fechar" 
                icon="pi pi-times" 
                class="p-button-text" 
                v-tooltip="'Fechar'"
                @click="$emit('onClose')" />
        </template>
    </Dialog>
</template>
<script>
import GridExames from './GridExames';
import FormProcedimento from './FormProcedimento';

export default {
    components: { GridExames, FormProcedimento },
    props: {
        cliente: {
            type: Object,
            required: true
        }
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        onEdit(record) {
            this.$refs.form.loadData(record);
        }
    }
}
</script>
<style lang="">
    
</style>