<template lang="">
    <Fieldset :legend="form.id ? 'Alteração' : 'Inclusão'">
        <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
                <label for="procedimento">Procedimento</label>
                <AppOnePageDropdown
                    id="procedimento"
                    v-model="form.procedimento"
                    optionLabel="name"
                    placeholder="Selecione o exame..."
                    :disabled="!!form.id"
                    :service="$serviceProcedimento"
                ></AppOnePageDropdown>
            </div>

            <div class="field col-12 md:col-3">
                <label for="valorPagar">Valor à pagar</label>
                <InputNumber id="valorPagar" v-model="form.valorPagar" mode="currency" currency="BRL"></InputNumber>
            </div>

            <div class="field col-12 md:col-3">
                <label for="valorCobrar">Valor de cobrança</label>
                <InputNumber id="valorCobrar" v-model="form.valorCobrar" mode="currency" currency="BRL"></InputNumber>
            </div>

            <div class="field col-12 md:col-2">
                <Button icon="pi pi-check" class="p-button-text p-button-primary" v-tooltip="'Salvar'" style="margin-top: 24px" @click="save" :loading="loadingSave" />
                <Button
                    v-if="form.id"
                    icon="pi pi-times"
                    class="p-button-text p-button-secondary"
                    v-tooltip="'Cancelar'"
                    style="margin-top: 24px; margin-left: 5px"
                    @click="cancel"
                />
            </div>
        </div>
    </Fieldset>
</template>
<script>
import BaseService from '../../../services/BaseService';

export default {
    props: {
        cliente: {
            type: Object,
            required: true
        }
    },
    emits: ['onSave'],
    data() {
        return {
            loadingSave: false,
            form: {}
        };
    },
    created() {
        this.$service = new BaseService('/prestador-valor');
        this.$serviceProcedimento = new BaseService('/procedures/find-by-prestador/' + this.$route.params.id);
    },
    methods: {
        async save() {
            try {
                this.loadingSave = true;
                const idPrestador = this.$route.params.id;
                const idProcedimento = this.form.procedimento.id;
                const idCliente = this.cliente.id;
                const dto = {
                    ...this.form,
                    idPrestador,
                    idProcedimento,
                    idCliente
                };
                const saved = await this.$service.save(dto);
                this.form = {};
                this.$emit('onSave', saved);
                this.loadingSave = false;
                this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Salvo com sucesso', life: 5000 });
            } catch (error) {
                this.loadingSave = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.message, life: 5000 });
            }
        },
        cancel() {
            this.form = {};
        },
        loadData(form) {
            this.form = JSON.parse(JSON.stringify(form));
        }
    }
};
</script>
