<template lang="">
    <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
        <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Chips v-model="filter" placeholder="Pesquisar" @add="load" @remove="load" />
        </div>
    </div>

    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />

    <DataTable dataKey="id" :value="records" :row-hover="true" class="p-datatable-sm" responsiveLayout="stack" breakpoint="640px" @sort="onSort">
        <template #empty> Nenhum registro encontrado. </template>

        <template #loading> Carregando registros. Aguarde ... </template>

        <Column bodyClass="text-left" headerStyle="width: 5rem;">
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-text p-button-info" v-tooltip="'Editar'" @click="onClickEditRecord(slotProps.data)" />
            </template>
        </Column>

        <Column :sortable="true" field="cliente.name" header="Cliente">
            <template #body="slotProps">
                {{ slotProps.data.cliente.name }}
                <i v-if="slotProps.data.statusSincronizacao == 'ERRO'"
                    class="pi pi-info-circle"
                    v-tooltip="slotProps.data.mensagemErro"
                    style="color: red; cursor: pointer"
                    @Click="openRetryDialog(slotProps.data)"
                />
            </template>
        </Column>

        <Column bodyClass="text-right" headerStyle="width: 5rem;">
            <template #body="slotProps">
                <Button
                    icon="pi pi-book"
                    class="p-button-text p-button-info"
                    v-tooltip="'Procedimentos para o cliente'"
                    @click="onClickOpenExames(slotProps.data)"
                />
            </template>
        </Column>

        <Column bodyClass="text-right" headerStyle="width: 5rem;">
            <template #body="slotProps">
                <Button
                    icon="pi pi-trash"
                    class="p-button-text p-button-danger"
                    v-tooltip="'Excluir'"
                    @click="onClickConfirmDeleteRecord(slotProps.data)"
                />
            </template>
        </Column>
    </DataTable>
    <Paginator
        :rows="perPage"
        :totalRecords="total"
        v-model:first="page"
        @page="onPage($event)"
        template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
        currentPageReportTemplate="Página {currentPage} de {totalPages}"
    >
    </Paginator>

    <AppDeleteDialog
        v-if="showAppDeleteDialog"
        :loadingRemove="loadingRemove"
        v-model:visible="showAppDeleteDialog"
        @onConfirm="onClickDeleteRecord"
        @onClose="onClickCloseDeleteRecord"
    >
    </AppDeleteDialog>

    <DialogExames
        v-if="showExamesDialog"
        :cliente="record.cliente"
        v-model:visible="showExamesDialog"
        @onClose="onClickCloseExamesDialog"
    ></DialogExames>
    <AppRetryDialog v-if="showRetryDialog" v-model:visible="showRetryDialog" :loading="loadingLiberacao"
        :title="liberacaoPendente.mensagemErro" @onRetry="reenviarLiberacao(this.records)"
        @onClose="showRetryDialog = false"
    />
</template>
<script>
import BaseService from '@/services/BaseService';
import { getClientBase } from '@/services/http';
import DialogExames from './DialogExames';

export default {
    components: { DialogExames },
    emits: ['onRemove', 'onEdit'],
    mounted() {
        this.$service = new BaseService('/prestador-clientes');
        this.load();
    },
    data() {
        return {
            record: {},
            records: [],
            filter: [],
            loadingLiberacao: false,
            page: 1,
            showRetryDialog: false,
            total: 0,
            loading: false,
            totalPage: 0,
            perPage: 10,
            showAppDeleteDialog: false,
            showExamesDialog: false,
            loadingRemove: false,
            sort: null,
            liberacaoPendente: {}
        };
    },
    methods: {
        async load() {
            this.loading = true;
            await this.loadData();
            this.loading = false;
        },
        openRetryDialog(data) {
            this.liberacaoPendente = data;
            this.showRetryDialog = true;
        },
        async reenviarLiberacao() {
            try{
                this.loadingLiberacao = true;
                await getClientBase().patch(`/prestador-clientes/atualizar-soc/${this.liberacaoPendente.idPrestador}/${this.liberacaoPendente.id}`);
                this.loadingLiberacao = false;
                this.showRetryDialog = false;
                this.$toast.add({ severity: 'success', summary: 'Liberação realizada com sucesso', life: 5000 });
                await this.load();
            } catch(err) {
                console.error(err);
                this.$toast.add({ severity: 'error', summary: 'Erro ao realizar liberação, favor verificar', life: 5000 });
            }
            
        },
        async loadData(page = 1) {
            try {
                const path = `/prestador-clientes/find-by-prestador/${this.$route.params.id}`;
                const { data } = await getClientBase().get(path, {
                    params: {
                        limit: this.perPage,
                        page: page ?? this.page,
                        filter: this.filter
                    }
                });
                this.records = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.message, life: 5000 });
            }
        },
        async onPage(event) {
            this.loadData(event.page + 1);
        },
        onClickEditRecord(record) {
            this.$emit('onEdit', record);
        },
        onClickConfirmDeleteRecord(record) {
            this.record = record;
            this.showAppDeleteDialog = true;
        },
        onClickCloseDeleteRecord() {
            this.showAppDeleteDialog = false;
        },
        async excluir(record) {
            try {
                this.loadingRemove = true;
                await this.$service.remove(record.id);
                this.$emit('onRemove', record);
                this.$toast.add({ severity: 'success', summary: 'Registro excluído com sucesso!', life: 3000 });
                this.loadingRemove = false;
            } catch (err) {
                const message = err?.response?.data?.message || err.message;
                this.$toast.add({ severity: 'error', summary: message, life: 10000 });
                this.loadingRemove = false;
                await this.load();
            }
        },
        async onClickDeleteRecord() {
            if (!this.record) {
                return;
            }

            await this.excluir(this.record);
            this.showAppDeleteDialog = false;
        },
        onClickOpenExames(record) {
            this.record = record;

            this.showExamesDialog = true;
        },

        onClickCloseExamesDialog() {
            this.showExamesDialog = false;
        },
        onSort(event) {
            this.currentSortField = event.sortField;
            this.currentSortOrder = event.sortOrder;
            const field = event.sortField
                ?.split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()
                ? `${event.sortField
                      ?.split(/(?=[A-Z])/)
                      .join('_')
                      .toLowerCase()}`
                : '';
            const order = event.sortOrder == 1 ? `ASC` : 'DESC';
            if (field) {
                this.sort = {
                    [field]: order
                };
            } else {
                this.sort = null;
            }

            this.load();
        }
    }
};
</script>
